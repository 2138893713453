:root {
  --alert: #ffd200;
  --azul: #2348ae;
  --branco: #fff;
  --card-shadow: 0px 18px 50px -15px #192a5933;
  --cinza-claro: #f2f2f2;
  --cinza-escuro: #646464;
  --cinza-mdio: #e6e6e6;
  --cinza-mdio-2: #a7a7a7;
  --creme: #f4f3dc;
  --laranja: #ea7526;
  --preto: #1f1f1f;
  --pretohalf: #1f1f1f80;
  --rosa: #f9d8d8;
  --text-main: #172755;
  --text-secondary: #8794ba;
  --verde: #3cfd79;
  --verde-escuro: #163e4c;
  --white: #fff;
}

.div-5 {
  justify-content: center;
}

.landing-page {
  flex-direction: column !important;
}

.landing-page .div {
  height: 4200px;
}

.container-page {
  min-height: 71vh;
  margin-top: 130px;
  padding: 0 75px;
}

.landing-page .footer {
  background-color: var(--verde-escuro);
  width: 100%;
  padding: 40px 5%;
}

.landing-page .text-wrapper-22 {
  color: var(--cinza-escuro);
  letter-spacing: 0;
  flex-direction: column;
  margin: 20px 0;
  font-family: Lexend, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  position: relative;
}

.landing-page .text-wrapper-22 a {
  color: var(--laranja);
}

.container-privacy-terms li {
  list-style: inside;
}

.container-privacy-terms strong {
  font-weight: bold;
}

.container-privacy-terms.privacy-terms-text .text-wrapper-22 {
  font-size: 15px;
}

.text-wrapper-22.text-bold {
  font-weight: bold;
}

.containerFooter {
  background-color: var(--verde-escuro);
}

.containerCopyright {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Lexend, Helvetica;
  font-size: .8rem;
  display: flex;
}

.copyrightLinks {
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
  display: flex;
}

.copyrightLinks a {
  color: #fff;
  text-decoration: underline;
}

.containerCopyright .copyrightText {
  margin: 20px 0;
}

.landing-page .social-buttons {
  margin-right: unset !important;
}
/*# sourceMappingURL=index.b5122de2.css.map */
